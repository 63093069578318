<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="300"
    :loading="loading"
    @close="onClose"
    @ok="onSubmitFun"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form>
        <e-info
          :labelCol="8"
          :valueCol="14"
          title="商品名称"
          :value="plan.goodsTitle"
        />
        <e-info
          :labelCol="8"
          :valueCol="14"
          title="商品单位"
          :value="plan.unit"
        />
        <e-info
          :labelCol="8"
          :valueCol="14"
          title="采购数量"
          :value="plan.count"
        />
        <e-input-number
          :labelCol="8"
          :valueCol="14"
          title="出库数量"
          v-model:value="count"
        />
        <e-textarea
          :labelCol="8"
          :valueCol="14"
          title="备注"
          v-model:value="remark"
        />
      </a-form>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import form from '@/mixins/form'
import { PlanItemClass } from '@/apis/plan'

const api = new PlanItemClass()
export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  components: {},
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)

    const title = ref('')
    const remark = ref('')
    const plan = ref({})
    const count = ref(1)

    const onClose = () => {
      count.value = 1
      loading.value = false
      visible.value = false
    }

    return {
      title,
      visible,
      loading,
      plan,
      count,
      remark,
      onClose
    }
  },

  methods: {
    open (formData) {
      this.plan = formData
      this.title = '直接出库到' + formData.name
      this.count = formData.count
      this.visible = true
    },

    onSubmitFun () {
      this.loading = true
      api
        .out({
          planItemId: this.plan.id,
          userId: this.plan.userId,
          quantity: this.count,
          remark: this.remark
        })
        .then(() => {
          this.$message.success('直接出库给' + this.plan.name + '成功')
          this.onClose()
        })
    }
  }
})
</script>

<style lang="less" scoped></style>
