<template>
  <div
    :class="{
      content: true,
      page: page.total > queryParams.Limit,
    }"
    ref="content"
  >
    <div class="main-content">
      <div class="search-box" ref="search">
        <a-form v-bind="searchLayout">
          <a-row>
            <a-col :sm="6" :xxl="4">
              <s-input
                title="商品名称"
                :labelCol="8"
                :valueCol="14"
                v-model:value="queryParams.GoodsTitle"
              />
            </a-col>

            <a-col :sm="6" :xxl="4">
              <s-tree-select
                :labelCol="8"
                :valueCol="14"
                title="商品分类"
                :fieldNames="replaceFields"
                v-model:value="queryParams.GoodsCategoryId"
                :options="treeData"
              />
            </a-col>

            <a-col :sm="8" :lg="6">
              <a-button type="primary" @click="getDataList(1)">查询</a-button>
              <a-button type="primary" style="margin-left: 15px" @click="exportExcel">导出Excel</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="data-list">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="false"
          :loading="loading"
          :row-key="(record) => record.id"
          :scroll="{ y: height, x: width }"
        >
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'index'">
              {{ (page.page - 1) * queryParams.Limit + index + 1 }}
            </template>

            <template v-if="column.key === 'totalPrice'">
              <span v-if="record.totalPrice > 0">{{ record.totalPrice }}</span>
              <span v-else>暂无报价</span>
            </template>

            <template v-if="column.key === 'isGoosOut'">
              {{ record.isGoosOut ? "已经出库" : "未出库" }}
            </template>

            <template v-if="column.key === 'supplierId'">
              <a-select
                v-model:value="record.supplierId"
                style="width: 100%"
                :options="suppliers"
                placeholder="供货商"
              ></a-select>
            </template>

            <template v-if="column.key === 'count'">
              <a-tooltip>
                <template #title>修改数量</template>
                <a @click.stop="editCount(record)">
                  <a-tag color="blue">{{ record.count }}</a-tag>
                </a>
              </a-tooltip>
            </template>

            <template v-if="column.key === 'unitPrice'">
              <a-input-number
                :step="0.01"
                :min="0"
                v-model:value="record.unitPrice"
              />
              <a-button type="primary" @click="updatePrice(record)">
                保存
              </a-button>
            </template>

            <template v-if="column.key === 'out'">
              <a-tooltip>
                <template #title>直接出库</template>
                <a @click.stop="$refs.eOut.open(record)">
                  <LogoutOutlined />
                </a>
              </a-tooltip>
            </template>
          </template>
        </a-table>
      </div>
    </div>

    <div class="pagination">
      <a-pagination
        :total="page.total"
        v-model:current="page.page"
        v-model:page-size="queryParams.Limit"
        :hide-on-single-page="true"
        :show-size-changer="true"
        :page-size-options="['20', '40', '100', '200']"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
      >
        <template #itemRender="{ page, type }">
          <a v-if="type === 'prev'" class="ant-pagination-item-link">上一页</a>
          <a v-else-if="type === 'next'" class="ant-pagination-item-link">
            下一页
          </a>
          <span v-else>{{ page }}</span>
        </template>
      </a-pagination>
      <span>数据总数：{{ page.total }}</span>
    </div>

    <EChangeNum ref="eChangeNum" @ok="okCount" />
    <EOut ref="eOut" />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { LogoutOutlined } from '@ant-design/icons-vue'
import { PlanItemClass } from '@/apis/plan'
import page from '@/mixins/page'
import EChangeNum from '@/components/forms/ChangeNum'
import EOut from '@/components/forms/plan/Out'

import dayjs from 'dayjs'
import fileDownload from 'js-file-download'
import { dataToTree } from '@/utils'

const api = new PlanItemClass()
export default defineComponent({
  mixins: [page],
  components: {
    EChangeNum,
    EOut,
    LogoutOutlined
  },
  setup () {
    const loading = ref(false)
    const height = ref(500)
    const width = ref(500)
    const treeData = ref([])
    const replaceFields = {
      value: 'id',
      key: 'id'
    }

    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 60
      },
      {
        title: '商品分类',
        key: 'goodsCategoryTitle',
        dataIndex: 'goodsCategoryTitle',
        width: 100
      },
      {
        title: '商品',
        key: 'goodsTitle',
        dataIndex: 'goodsTitle',
        width: 150
      },
      {
        title: '库存',
        key: 'quantity',
        dataIndex: 'quantity',
        width: 70
      },
      {
        title: '订购数量',
        key: 'count',
        dataIndex: 'count',
        width: 100
      },
      {
        title: '单位',
        key: 'unit',
        dataIndex: 'unit',
        width: 70
      },
      {
        title: '规格',
        key: 'specification',
        dataIndex: 'specification',
        width: 150
      },
      {
        title: '供货商',
        key: 'supplierId',
        dataIndex: 'supplierId',
        width: 250
      },
      {
        title: '设置单价',
        key: 'unitPrice',
        dataIndex: 'unitPrice',
        width: 200
      },
      {
        title: '总价',
        key: 'totalPrice',
        dataIndex: 'totalPrice',
        width: 120
      },
      {
        title: '是否出库',
        key: 'isGoosOut',
        dataIndex: 'isGoosOut',
        width: 120
      },
      {
        title: '出库',
        key: 'out',
        dataIndex: 'out',
        width: 80,
        fixed: 'right'
      }
    ]

    const suppliers = ref([])
    return {
      columns,
      loading,
      height,
      width,
      suppliers,
      treeData,
      replaceFields
    }
  },
  created () {
    this.queryParams.PlanId = this.$route.params.planId
    this.getDataList(1)
    this.onGetAllSuppliers()
    this.getCategorys()
  },
  methods: {
    async getCategorys () {
      const resp =
        this.$store.state.data.ALL_GOODSCATEGORYS.length > 0
          ? this.$store.state.data.ALL_GOODSCATEGORYS
          : await this.$store.dispatch('GetAllGoodsCategorys')
      const data = dataToTree(resp, 0)
      this.treeData = data
    },
    async onGetAllSuppliers () {
      const list =
        this.$store.state.data.ALL_SUPPLIER.length > 0
          ? this.$store.state.data.ALL_SUPPLIER
          : await this.$store.dispatch('GetAllSuppliers')

      const nlist = []

      list.map((e) => {
        nlist.push({
          label: e.name,
          value: e.id
        })
      })
      this.suppliers = nlist
    },
    getDataList (page) {
      this.loading = true
      this.page.page = page
      const q = { ...this.queryParams }
      q.Offset = (page - 1) * q.Limit
      api.getItems(q).then((resp) => {
        this.list = resp.data
        this.page.total = resp.total
        this.loading = false
        this.getTableHeight()
      })
    },
    resetFun () {
      this.queryParams = {
        Limit: 20
      }

      this.getDataList(1)
    },

    updatePrice (record) {
      if (!record.unitPrice) {
        this.$message.error('请设置价格')
        return
      }
      api
        .setPrice(
          record.planId,
          record.goodsId,
          record.unitPrice,
          record.supplierId
        )
        .then(() => {
          this.$message.success('修改单价成功！')
          this.getDataList(this.page.page)
        })
    },

    editCount (record) {
      this.id = record.id
      this.$refs.eChangeNum.open(record.count)
    },

    okCount (e) {
      api
        .edit({
          id: this.id,
          count: e
        })
        .then(() => {
          this.$refs.eChangeNum.handleClose()
          this.getDataList(this.page.page)
        })
    },

    getTableHeight () {
      const paddingBottom = this.page.total > this.queryParams.Limit ? 50 : 9
      const mainPadding = 30
      const thead = 56
      this.height =
        this.$refs.content.offsetHeight -
        this.$refs.search.offsetHeight -
        thead -
        paddingBottom -
        mainPadding

      this.width = this.$refs.search.offsetWidth - 10
    },

    exportExcel () {
      api
        .exportPlanItems({
          PlanId: this.queryParams.PlanId
        })
        .then((resp) => {
          fileDownload(
            resp,
            '采购清单' + dayjs().format('YYYYMMDDHHmmss') + '.xlsx'
          )
        })
    }
  }
})
</script>

<style lang="less" scoped>
.goods-info {
  display: flex;
  .goods-image {
    width: 100px;
    height: 100px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .goods-title {
    padding-left: 10px;
    h1 {
      font-size: 14px;
      padding: 0;
      margin: 0;
      font-weight: 600;
      line-height: 30px;
    }
    p {
      font-size: 12px;
      color: #9b9b9b;
      line-height: 20px;
      height: 40px;
      padding: 0;
      margin: 0;
    }
    .goods-tags {
      font-size: 12px;
      line-height: 30px;
      height: 30px;
    }
  }
}
</style>
