<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="300"
    @close="handleClose"
    @ok="handleOk"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form v-bind="layout">
        <e-input-number :labelCol="4" :valueCol="16" :title="label" v-model:value="num" :min="min" :max="max" />
      </a-form>
    </a-spin>
  </e-modal>
</template>
<script>
import { defineComponent, ref } from 'vue'
import form from '@/mixins/form'
export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  props: {
    title: {
      type: String,
      default: '修改数值'
    },
    label: {
      type: String,
      default: '数值'
    },
    min: {
      type: Number,
      default: -9999
    },
    max: {
      type: Number,
      default: 9999
    }
  },
  setup (props, { emit }) {
    const visible = ref(false)
    const loading = ref(false)
    const num = ref(0)

    const handleClose = () => {
      num.value = 0
      loading.value = false
      visible.value = false
    }

    const handleOk = () => {
      loading.value = true
      emit('ok', num.value)
    }

    return {
      loading,
      visible,
      num,
      handleClose,
      handleOk
    }
  },

  methods: {
    open (num) {
      this.num = num
      this.visible = true
    }
  }
})
</script>

<style lang="less" scoped></style>
